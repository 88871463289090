import React from 'react'
import { Link } from 'gatsby'
import slugHandler from '../api/slugHandlerBrowser'
import { MdLanguage, MdArrowDropDown } from 'react-icons/md'
import "./languageSwitcher.css"

const LanguageSwitcher = ({ activeLanguage, languages, slug, color }) => {

  return (
    <div className="langSwitch">
        <MdLanguage className="icon-md"/>
        <span className="vam">{ activeLanguage }</span>
        <MdArrowDropDown className="icon-md"/>

        <div className="langSwitch-menu">
      {
      languages.map(lang => {
          const url = slugHandler(
            lang,
            slug
          );
          return (
                <Link
                key={lang}
                className={`langSwitch-item ${activeLanguage === lang ? 'langSwitch-item--active' : ''}`}
                to={url}
                >
                {lang}&nbsp;
                </Link>
            )
            }
      )}

        </div>
    </div>
  )
}

export default LanguageSwitcher