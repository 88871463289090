import React from 'react'

import {
  FaFacebookSquare,
  FaInstagramSquare,
} from 'react-icons/fa'
import { MdEmail} from 'react-icons/md'

const SocialIcons = ({ settings }) => {
  const { email, instagram, facebook } = settings;
  return (
      <div className="tar">
        <a href={"https://www.instagram.com/"+instagram} target="_blank" rel="noreferrer" className="link-1">
          <FaInstagramSquare className="icon-md"></FaInstagramSquare>
        </a>
        <a href={"https://www.facebook.com/"+facebook} target="_blank" rel="noreferrer" className="link-1">
          <FaFacebookSquare className="icon-md"></FaFacebookSquare>
        </a>
        <a href={"mailto: "+email} target="_blank" rel="noreferrer" className="link-1">
          <MdEmail className="icon-md--email"></MdEmail>
        </a>
      </div>
  )
}

export default SocialIcons