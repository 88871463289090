
import React from "react"
import "./mobileMenuEntry.css"

function MobileMenuEntry(props){
    let letters = props.children.split("");
    // console.warn(letters);
    return (
        <div className="moblile-menu-entry">
            {
                letters.map((letter, i)=>{
                    if (letter === " ") return <span key={i}>&nbsp;</span>;
                    return <span key={i} className={"moblile-menu-letter moblile-menu-letter--" + (i%2 ? "even" : "odd")}>{letter}</span>
                })
            }
        </div>
    );
}

export default MobileMenuEntry