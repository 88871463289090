import React from 'react'
import { RiCloseLine, } from 'react-icons/ri'
import { Link } from "gatsby"
import Logo from "./logo"
import LanguageSwitcher from './languageSwitcher'
import SocialIcons from './socialIcons'
import MobileMenuEntry from './MobileMenuEntry/mobileMenuEntry'
import "./mobileMenu.css"
import "./topMenu.css"
import { FaWhatsapp } from 'react-icons/fa'
import { MdEmail, MdLocationOn } from 'react-icons/md'


function MobileMenu(props) {
    let classes = "mobile-menu page color-text" + (props.visible ? "" : " dn");
    const nav = props.translation.nav;
    return (
        <div className={classes}>
            <div className="mobile-menu-out">
                <div className="top-menu-in container-1200 mb2">
                    <Link to={nav[0].url}><Logo></Logo></Link>
                    <button className="top-menu-item link-1" onClick={props.onClose}>
                        <RiCloseLine className="icon-md"></RiCloseLine>
                    </button>
                </div>
                <div className="mobile-menu-in">
                    <div className="mobile-menu-items mb2">
                    {
                        nav.map((item, i) => (
                            <h4 key={item.title} className="tac mobile-menu-item mobile-menu-item--color">
                                <Link to={item.url} className="db" onClick={props.onClose}>
                                    <MobileMenuEntry>{item.title}</MobileMenuEntry> 
                                </Link>
                            </h4>
                        ))
                    }
                    
                    </div>
                    <div className="df mb1">
                        <SocialIcons settings={props.settings}></SocialIcons>
                        <LanguageSwitcher activeLanguage={props.lang || "fi"} languages={props.languages} slug={props.slug}></LanguageSwitcher>
                    </div>
                    <div className="">
                    <a href={"tel: "+props.settings.phone} target="_blank" rel="noreferrer" className="vp-footer-link vp-footer-link--icon">
                        <FaWhatsapp className="icon-md"/>
                        {props.settings.phone}
                    </a>
                    <a href={"mailto: "+props.settings.email} target="_blank" rel="noreferrer" className="vp-footer-link">
                          <MdEmail className="icon"></MdEmail>
                          {props.settings.email}
                      </a>
                    <a href={props.settings.googlemaplink} target="_blank" rel="noreferrer" className="vp-footer-link vp-footer-link--icon">
                        <MdLocationOn className="icon-md"/>
                        {props.settings.address}, {props.settings.address2}
                    </a>
                    </div>
                    

    
                </div>
            </div>
        </div>
    )
}


export default MobileMenu