
import React, { 
  // useEffect, 
  useState 
  } from "react"
import { Link } from "gatsby"
import Logo from "./logo"
import {
  // FaFacebookSquare,
  // FaInstagramSquare,
  FaWhatsapp
} from 'react-icons/fa'
import { useStaticQuery, graphql } from "gatsby"
import MobileMenu from "./mobileMenu"
import LanguageSwitcher from "./languageSwitcher"
// import { ChromePicker } from 'react-color';
import "./topMenu.css"
import { MdEmail } from "react-icons/md"

function TopMenu (props){
  // console.log(props);
  const data = useStaticQuery(graphql`
  {
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          email
          facebook
          sitename
          address
          address2
          hours
          phone
          instagram
          twitter
          googlemaplink
          googlemaphtml
        }
      }
    }
  }
`)
const settings = data.settings.childMdx.frontmatter;

let [mobileMenuVisible, setMobileMenuVisible] = useState(false);
// let [rootElement, setRootElement] = useState(false);

// useEffect(() => {
//   setRootElement(window && window.document && window.document.documentElement);
// }, []);

// const [colors, setColors] = useState({ 
//   bg: {r: 242, g: 236, b: 224, a: 1},
//   acc1: {r: 255, g: 192, b: 0, a: 1},
//   acc2: {r: 221, g: 47, b: 85, a: 1},
//   acc3: {r: 139, g: 222, b: 203, a: 1},

// })
    return  (<div className="top-menu container-1200">
      <div className="top-menu-in">
        <Link to={props.translation.nav[0].url}><Logo></Logo></Link>
        <div className="top-menu-links top-menu-links--desktop">
          <div className="top-menu-items">
            <div className="top-menu-contacts top-menu-item tablet">
              <a href={`tel:${settings.phone}`} className="top-menu-contact link-1">
                <FaWhatsapp className="icon"/> <span className="vam">{settings.phone}</span> 
              </a>
              <a href={"mailto: "+settings.email} target="_blank" rel="noreferrer" className="top-menu-contact link-1">
                <MdEmail className="icon" /><span className="vam">{settings.email}</span> 
              </a>
            </div>
          <div className="top-menu-item tablet df">
            {/* <a href={`tel:${settings.phone}`} className="top-menu--mobile link-1">
              <FaWhatsapp className="icon-md"/>
            </a> */}
            {/* <a href={"https://www.instagram.com/"+settings.instagram} target="_blank" rel="noreferrer" className="link-1">
              <FaInstagramSquare className="icon-md"></FaInstagramSquare>
            </a>
            <a href={"https://www.facebook.com/"+settings.facebook} target="_blank" rel="noreferrer" className="link-1">
              <FaFacebookSquare className="icon-md"></FaFacebookSquare>
            </a> */}
            {/* <a href={"mailto: "+settings.email} target="_blank" rel="noreferrer" className="link-1">
              <MdEmail className="icon-md--email"></MdEmail>
            </a> */}
            <LanguageSwitcher activeLanguage={props.lang || "fi"} languages={props.languages} slug={props.slug}></LanguageSwitcher>
          </div>
          <button className={"top-menu-item top-menu-trigger" + (mobileMenuVisible ? " top-menu-trigger--open" : "")} onClick={()=>setMobileMenuVisible(true)}>
            <div className="top-menu-trigger__circle"></div>
            <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" className="top-menu-trigger__icon">
            <g fill="currentColor" stroke="currentColor" strokeWidth="0" fillRule="nonzero">
              <circle cx="3.5" cy="28.5" r="2.6"/>
              <circle cx="16.1" cy="28.5" r="2.6"/>
              <circle cx="28.5" cy="28.5" r="2.6"/>
              <circle cx="3.5" cy="3.5" r="2.6"/>
              <circle cx="16.1" cy="3.5" r="2.6"/>
              <circle cx="28.5" cy="3.5" r="2.6"/>
              <circle cx="3.5" cy="15.9" r="2.6"/>
              <circle cx="16.1" cy="15.9" r="2.6"/>
              <circle cx="28.5" cy="15.9" r="2.6"/>
            </g>
          </svg>
          </button>
          </div>
        </div>
      </div>
        <div className="top-menu-contacts top-menu-contacts--tablet tablet-to top-menu-item">
          <a href={`tel:${settings.phone}`} className="top-menu-contact link-1">
            <FaWhatsapp className="icon"/> <span className="vam">{settings.phone}</span> 
          </a>
          <a href={"mailto: "+settings.email} target="_blank" rel="noreferrer" className="top-menu-contact link-1">
            <MdEmail className="icon" /><span className="vam">{settings.email}</span> 
          </a>
        </div>
    <MobileMenu lang={props.lang} languages={props.languages} translation={props.translation} settings={settings} visible={mobileMenuVisible} onClose={()=>setMobileMenuVisible(false)}></MobileMenu>
    {/* <div className="colors-out">
      <div className="colors-trigger">Colors</div>
      { rootElement && <div className="colors df">
        <ChromePicker
          color={ colors.bg }
          onChangeComplete={ color => {
            console.warn(color)
            rootElement.style.setProperty('--color-paper', `${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}` );
            setColors({...colors, bg: color.rgb});
          } }
        />
        <ChromePicker
          color={ colors.acc1 }
          onChangeComplete={ color => {
            console.warn(color)
            rootElement.style.setProperty('--color-accent-1', `${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}` );
            setColors({...colors, acc1: color.rgb});
          } }
        />
        <ChromePicker
          color={ colors.acc2 }
          onChangeComplete={ color => {
            console.warn(color)
            rootElement.style.setProperty('--color-accent-2', `${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}` );
            setColors({...colors, acc2: color.rgb});
          } }
        />
        <ChromePicker
          color={ colors.acc3 }
          onChangeComplete={ color => {
            console.warn(color)
            rootElement.style.setProperty('--color-accent-3', `${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}` );
            setColors({...colors, acc3: color.rgb});
          } }
        />
        </div>
        }
    </div> */}
  </div>);
}

export default TopMenu